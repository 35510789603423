import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SiteStock } from 'src/app/objects/siteStock';
import { VendorArticle } from 'src/app/objects/vendorArticle';
import { ConnectControlCentreService } from 'src/app/services/connect-control-centre.service';
import { GlobalService } from 'src/app/services/global.service';
import { firstValueFrom } from 'rxjs';
import { StockMatrixComponent } from 'src/app/modals/stock-matrix/stock-matrix.component';
import { ProductImageModalComponent } from 'src/app/modals/product-image-modal/product-image-modal.component';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent  implements OnInit {

  @Input() vendorArticleId;
  product: VendorArticle = new VendorArticle;
  stock: SiteStock[] = [];
  fetchingStock = true;
  qty = 0;
  allocated = 0;


  constructor(private modalController: ModalController, private alertController: AlertController, 
    private connectService: ConnectControlCentreService, public globalService: GlobalService) { }

  ngOnInit() {
    this.getProduct(this.vendorArticleId);
  }

  async getProduct(vendorArticleId){
    this.product = await firstValueFrom(this.connectService.getVendorArticleById(vendorArticleId, true, true));
    this.getStockMatrix(this.product.vendorArticleId);

  }

  async getStockMatrix(vaId){
    this.fetchingStock = true;
    this.stock = await firstValueFrom(this.connectService.getStockMatrix(vaId));

    
    this.stock.forEach(site => {
      this.qty += site.qty;
      this.allocated += site.allocatedQty;
    });

    this.fetchingStock = false;
  }

  async showStockMatrix(vaId){

    const modal = await this.modalController.create({
      component: StockMatrixComponent, componentProps: {vaId: vaId}
    });

    await modal.present();
    
  }

  async openProductImages(product: VendorArticle){

    const modal = await this.modalController.create({
      component: ProductImageModalComponent, componentProps: {product: product}
    });

    await modal.present();
    
  }

  close(){

    this.modalController.dismiss();
    
  }




}
