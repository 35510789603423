import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent  implements OnInit {
  
  message = "";

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  dismissModal() {

    this.modalController.dismiss();
  
  }

}
