import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ConnectControlCentreService } from 'src/app/services/connect-control-centre.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-till-operator-modal',
  templateUrl: './till-operator-modal.component.html',
  styleUrls: ['./till-operator-modal.component.scss'],
})
export class TillOperatorModalComponent  implements OnInit {

  public alertInputs = [
    {
      name: 'swipeCode',
      type: 'text',
      placeholder: 'Swipe Code',
    }
    
  ];
  public alertButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        console.log('Alert canceled');
      },
    },
    {
      text: 'OK',
      role: 'confirm',
      handler: (data) => {
        console.log('Alert confirmed');
        this.getTillOperator(data.swipeCode);
      },
    },
  ];

  constructor(private modalController: ModalController, private alertController: AlertController, private connectService: ConnectControlCentreService) { }

  ngOnInit() {
    this.showSwipeCodeAlert();
  }

  showSwipeCodeAlert(){
    this.presentAlert();
  }

  async getTillOperator(code){
    //let tillOperator = await firstValueFrom(this.connectService.getTillOperator(code));
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Sign In',
      subHeader: 'Enter swipe code',
      message: 'Enter swipe code',
      buttons: ['OK'],
      //inputs: this.alertInputs
    });

    await alert.present();
  }
  
  close(){

    this.modalController.dismiss();
    
  }

  async save(){

    

  }

}
