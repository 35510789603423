import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { Site } from 'src/app/objects/site';
import { ConnectControlCentreService } from 'src/app/services/connect-control-centre.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.scss'],
})
export class SiteSelectComponent  implements OnInit {

  sites: Site[] = [];
  busy: boolean = true;

  constructor(private connectService: ConnectControlCentreService, public globalService: GlobalService, private modalController: ModalController) { }

  ngOnInit() {

    this.getSites();
  }

  async getSites(){
    this.busy = true;
    this.globalService.sites = (await firstValueFrom(this.connectService.getSites())).filter(site => site.siteTypeDomainId != 6010 && site.siteTypeDomainId != "WEBSITE");
    this.busy = false;

  }

  async setSite(site: Site){
    this.globalService.activeSite = site;
    await new Promise(f => setTimeout(f, 500));
    this.modalController.dismiss();
  }

}
