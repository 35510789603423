import { AdditionalImage } from "./additionalImage";
import { Article } from "./article";
import { SupplierVendorArticle } from "./supplierVendorArticle";

export class VendorArticle{
    additionalImages: AdditionalImage[]; 
    allocatedStock: number;
    article: Article;
    barcode: string;
    binLocation: string;
    binLocCode: string;
    imgUrl: string;
    masterVendorArticleId: number;
    minQty: number = 1;
    name: string;
    optionIds: [];
    options: VendorArticle[];
    parentVendorArticleId: number;
    price: number;
    purchaseVendorArticles: SupplierVendorArticle[];
    qtyToAdd: number = 1; 
    stockQty: number; 
    vatCodeDomainId: number;
    vendorArticleCode: string;
    vendorArticleId: number;
    
  }