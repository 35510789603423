import { Injectable } from '@angular/core';
import { AuthToken } from '../objects/authToken';
import { ConnectControlCentreService } from './connect-control-centre.service';
import { Observable, firstValueFrom } from 'rxjs';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TillOperator } from '../objects/tillOperator';
import { LoginService } from './login.service';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { SiteSelectComponent } from '../modals/site-select/site-select.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authToken: AuthToken;

  constructor(private http: HttpClient, private alertController: AlertController, private toastController: ToastController, 
    private loadingController: LoadingController, private device: Device, private modalController: ModalController, private globalService: GlobalService) { }

  authenticate(){

  }

  async presentToast(position: 'top' | 'middle' | 'bottom', message, duration, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      color: color
    });

    await toast.present();
  }

  async login(code){
    const loading = await this.loadingController.create({
      message: 'Processing...'
    });

    try{
      
      loading.present();

      let authToken = await firstValueFrom(this.getAuthToken(code));
      this.setAuthToken(authToken);
      this.getTillOperator(code);
      loading.dismiss();
    }

    catch(e){
      loading.dismiss();
      this.presentToast("top", "Could not login, please try again", 2000,"danger");
      this.tillOperatorLogin();
    }
    
    //console.log("auth: " + authToken);
    //this.getTillOperator(code);
  }

  setAuthToken(authToken: AuthToken){
    this.authToken = authToken;
    this.calcExpiresOnDate(this.authToken);

  }

  calcExpiresOnDate(authToken){

    let expiryDate = new Date();
    expiryDate.setSeconds(expiryDate.getSeconds() + authToken.expires_in - 1200);
    //expiryDate.setSeconds(expiryDate.getSeconds() + 10);
    authToken.expiresOn = expiryDate;
    console.log("expiresOn: " + authToken.expiresOn);

  }

  async hasAccessTokenExpired(token){
    let now = new Date();
    if (now.getTime() > this.authToken.expiresOn.getTime()){
      console.log("access token has expired");
      let authToken = await firstValueFrom(this.getAuthTokenFromRefresh());
      this.setAuthToken(authToken);
    }
    
  }

  getOperator(code): Observable<TillOperator> {
    return this.http.get<TillOperator>(
      `${environment.apiUrl}/${environment.firebird}/tillOperator?swipeCode=${code}`,
      {
        headers: {
          Authorization: `Bearer ${this.authToken.access_token}`
        }
      }
    );
  }

  async getTillOperator(code){

    const loading = await this.loadingController.create({
      message: 'Processing...'
    });
    loading.present();
    let tillOperator = await firstValueFrom(this.getOperator(code));

    if (tillOperator){
      this.globalService.currentUser = tillOperator;
      this.presentToast("bottom", "Now logged in as " + tillOperator.tillOperatorName,2000,"success");
      //this.checkDeviceIsAuthorised();
      this.showSiteSelectionModal();
    }
    else {
      this.presentToast("bottom", "Could not login ",2000,"danger");
    }

    loading.dismiss();
  }

  async checkDeviceIsAuthorised(){
    console.log(this.device.uuid);

    let isAuth = await firstValueFrom(this.checkDeviceUuid(1234));

    if (!isAuth && this.device.uuid){
      
      this.showDeviceNotAuthorisedAlert();

      return;
    }

  }

  async showSiteSelectionModal(){
    const modal = await this.modalController.create({
      component: SiteSelectComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  async showDeviceNotAuthorisedAlert(){
    this.presentToast("bottom", "This device is not authorised. Please contact Intelligent Retail ",3000,"danger");
      const alert = await this.alertController.create({
        header: 'Device Not Authorised',
        subHeader: 'Please contact Intelligent Retail',
        //message: 'Enter swipe code',
        buttons: [],
        inputs: [],
        backdropDismiss: false
      });

      await alert.present();
  }

  checkDeviceUuid(uuid): Observable<String> {
    return this.http.get<String>(
      `${environment.apiUrl}/${environment.firebird}/device/handheldScanner/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${this.authToken.access_token}`
        }
      }
    );
  }

  async tillOperatorLogin(){

    

    let alertInputs = [
      {
        name: 'swipeCode',
        inputmode: 'email',
        placeholder: 'Swipe Code',
      }
      
    ];
    let alertButtons = [
      {
        text: 'OK',
        role: 'confirm',
        handler: (data) => {
          console.log('Alert confirmed');
          this.login(data.swipeCode);
        },
      },
    ];

    const alert = await this.alertController.create({
      header: 'Sign In',
      subHeader: 'Enter swipe code',
      //message: 'Enter swipe code',
      cssClass: "login-alert",
      buttons: alertButtons,
      inputs: alertInputs,
      backdropDismiss: false
    });

    await alert.present();
  }

  getAuthToken(swipeCode): Observable<AuthToken> {

    const body = new URLSearchParams();
    body.set("username", environment.firebird + ":" + swipeCode);
    body.set("password", "");
    body.set("grant_type", "password");

    const headers = new HttpHeaders({

      'Content-Type': 'application/x-www-form-urlencoded',

      'Authorization': 'Basic SGFuZGhlbGQgU2Nhbm5lcjo1MDFmNzFlODg2ZWU='

    });

    return this.http.post<AuthToken>(
      `${environment.apiUrl}/oauth/token`, body,

      {
        headers: headers
        
      }
      
    );
  }

  getAuthTokenFromRefresh(): Observable<AuthToken> {

    const body = new URLSearchParams();
    body.set("refresh_token", this.authToken.refresh_token);
    body.set("grant_type", "refresh_token");

    const headers = new HttpHeaders({

      'Content-Type': 'application/x-www-form-urlencoded',

      'Authorization': 'Basic SGFuZGhlbGQgU2Nhbm5lcjo1MDFmNzFlODg2ZWU='

    });

    return this.http.post<AuthToken>(
      `${environment.apiUrl}/oauth/token`, body,

      {
        headers: headers
        
      }
      
    );
  }

  
}
