import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-click-collect-order',
  templateUrl: './click-collect-order.component.html',
  styleUrls: ['./click-collect-order.component.scss'],
})
export class ClickCollectOrderComponent implements OnInit {

  order = {
    id: 123456789,
    total: 67,
    customer: "Lee Jordan",
    items: [{
      title: "Christmas Pudding Hat",
      price: 10,
      qty: 2,
      image: "https://www.antiquerose.co.uk/img/product/shower-cap-waterproof-christmas-pudding-brown-3025281-160.jpg",
      bin: "A02"
    },
    {
      title: "C3PO Mug",
      price: 11.20,
      qty: 1,
      image: "https://www.antiquerose.co.uk/img/product/mug-metallic-stoneware-star-wars-c3po-gold-3025019-160.jpg",
      bin: "B021"
    },
    {
      title: "Red Reindeer",
      price: 29.00,
      qty: 1,
      image: "https://www.antiquerose.co.uk/img/product/advent-calendar-wood-reindeer-nordic-red-3024506-160.jpg",
      bin: "LJ67"
    }]
  }
  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  dismissModal() {

      this.modalController.dismiss();
    
  }

}
