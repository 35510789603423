import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Site } from '../objects/site';
import { SiteStock } from '../objects/siteStock';
import { StockTake } from '../objects/stockTake';
import { VendorArticle } from '../objects/vendorArticle';
import { PurchaseOrder } from '../objects/purchaseOrder';
import { TransactionLine } from '../objects/transactionLine';
import { GoodsInReasonCode } from '../objects/goodsInReasonCode';
import { GoodsOutReasonCode } from '../objects/goodsOutReasonCode';
import { TillOperator } from '../objects/tillOperator';
import { AuthToken } from '../objects/authToken';
import { AuthenticationService } from './authentication.service';
import { Vendor } from '../objects/vendor';


export interface ApiResult {
  page: number;
  results: any[];
  total_pages: number;
  total_results: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConnectControlCentreService {

  constructor(private http: HttpClient, private authService: AuthenticationService) { }


  getSites(): Observable<Site[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<Site[]>(
      `${environment.apiUrl}/${environment.firebird}/site`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }
 
  getProductViaBarcode(barcode: string): Observable<VendorArticle[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<VendorArticle[]>(
      `${environment.apiUrl}/${environment.firebird}/vendorArticle?inclFullText=true&supplierBarcode=${barcode}&barcode=${barcode}&shortDescription=${barcode}&supplierCode=${barcode}&vendorArticleCode=${barcode}`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  getProductsForVendor(vendorId: number): Observable<VendorArticle[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<VendorArticle[]>(
      `${environment.apiUrl}/${environment.firebird}/vendorArticle?vendorId=${vendorId}`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  getAllVendors(): Observable<Vendor[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<Vendor[]>(
      `${environment.apiUrl}/${environment.firebird}/vendor/list`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  setStockTake(siteId: number, stockTake: StockTake[]): Observable<any> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.post<ApiResult>(
      `${environment.apiUrl}/${environment.firebird}/stockTake/siteId/${siteId}`,
      stockTake,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  checkDeviceAuthorised(uuid: number): Observable<any> {

    // handled in auth service

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.post<ApiResult>(
      `${environment.apiUrl}/${environment.firebird}/device/handheldScanner/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  getPurchaseOrder(id): Observable<PurchaseOrder> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<PurchaseOrder>(
      `${environment.apiUrl}/${environment.firebird}/transaction/${id}`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  getVendorArticleById(id, inclFullText: boolean, inclAdditionalImage: boolean = false): Observable<VendorArticle> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<VendorArticle>(
      `${environment.apiUrl}/${environment.firebird}/vendorArticle/vendorArticleId/${id}?inclAdditionalImage=${inclAdditionalImage}&inclFullText=${inclFullText}`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  getOptions(id): Observable<VendorArticle[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<VendorArticle[]>(
      `${environment.apiUrl}/${environment.firebird}/vendorArticle/vendorArticleId/${id}/option`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  

  productSearch(id, inclFullText: boolean, inclAdditionalImage: boolean = false): Observable<VendorArticle> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<VendorArticle>(
      `${environment.apiUrl}/${environment.firebird}/vendorArticle/vendorArticleId/${id}?inclAdditionalImage=${inclAdditionalImage}&inclFullText=${inclFullText}`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  

  addProductToTransaction(poId, vaId, qty): Observable<Response> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);


    const addItem = [
      {
        "vendorArticle" : {
          "vendorArticleId": vaId
        },
        "quantity": qty
      }];
       
      
    

    return this.http.post<Response>(
      `${environment.apiUrl}/${environment.firebird}/transaction/addItem/${poId}`, addItem,

      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  removeProductFromPurchaseOrder(poId, transactionLineId): Observable<Response> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.delete<Response>(
      `${environment.apiUrl}/${environment.firebird}/transaction/removeItem/${poId}/transactionLineIds/${transactionLineId}`,

      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  getGoodsInReasonCodes(): Observable<GoodsInReasonCode[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<GoodsInReasonCode[]>(
      `${environment.apiUrl}/${environment.firebird}/stock/reason?isGoodsIn=true`, 

      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  getGoodsOutReasonCodes(): Observable<GoodsOutReasonCode[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<GoodsOutReasonCode[]>(
      `${environment.apiUrl}/${environment.firebird}/stock/reason?isGoodsIn=false`, 

      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  moveStockForTransaction(transactionId, vaId, qty, siteId): Observable<Response> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    const stockMovement = {
      items: [

        {
          "quantity": qty,
          "vendorArticleId": vaId
        }
      ],
      "tillOperatorId": 0
    }
    

    return this.http.post<Response>(
      `${environment.apiUrl}/${environment.firebird}/transaction/moveStock/${transactionId}?siteId=${siteId}`, stockMovement,

      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  moveStock(stockMovement: any, siteId): Observable<Response> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.post<Response>(
      `${environment.apiUrl}/${environment.firebird}/stock/siteId/${siteId}`, stockMovement,

      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

  recalcStock(vaId: any, siteId): Observable<Response> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.post<Response>(
      `${environment.apiUrl}/${environment.firebird}/stock/recalc/siteId/${siteId}/vaId/${vaId}`,"",

      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }
  
  getStockMatrix(vaId): Observable<SiteStock[]> {

    this.authService.hasAccessTokenExpired(this.authService.authToken);

    return this.http.get<SiteStock[]>(
      `${environment.apiUrl}/${environment.firebird}/vendorArticle/quantity/vaId/${vaId}`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.authToken.access_token}`
        }
      }
    );
  }

}
