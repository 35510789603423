import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { VendorArticle } from 'src/app/objects/vendorArticle';
import { ConnectControlCentreService } from 'src/app/services/connect-control-centre.service';
import { firstValueFrom } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { StockMatrixComponent } from '../stock-matrix/stock-matrix.component';
import { ProductImageModalComponent } from '../product-image-modal/product-image-modal.component';
import { SiteStock } from 'src/app/objects/siteStock';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-product-info-modal',
  templateUrl: './product-info-modal.component.html',
  styleUrls: ['./product-info-modal.component.scss'],
})
export class ProductInfoModalComponent  implements OnInit {

  vendorArticleId;
  product: VendorArticle = new VendorArticle;
  stock: SiteStock[] = [];
  stockForSite: SiteStock;
  fetchingStock = true;
  qty = 0;
  allocated = 0;
  fetching


  constructor(private modalController: ModalController, private alertController: AlertController, 
    private connectService: ConnectControlCentreService, public globalService: GlobalService) { }

  ngOnInit() {
    this.getProduct(this.vendorArticleId);
    this.getStockMatrix(this.vendorArticleId);
  }

  async getProduct(vendorArticleId){
    this.product = await firstValueFrom(this.connectService.getVendorArticleById(vendorArticleId, true, true));
    console.log(this.product);
    
    let vaId = this.product.masterVendorArticleId ? this.product.masterVendorArticleId : vendorArticleId;
    this.product.options = await this.getOptions(vaId);
    

  }

  async getOptions(vendorArticleId){
    let options = await firstValueFrom(this.connectService.getOptions(vendorArticleId));
    return options;
  }

  async getStockMatrix(vaId){
    this.fetchingStock = true;
    this.stock = await firstValueFrom(this.connectService.getStockMatrix(vaId));

    this.stockForSite = this.stock.find(stock => stock.siteId == this.globalService.activeSite.siteId);
    
    // this.stock.forEach(site => {
    //   this.qty += site.qty;
    //   this.allocated += site.allocatedQty;
    // });

    this.fetchingStock = false;
  }

  async showStockMatrix(vaId){

    const modal = await this.modalController.create({
      component: StockMatrixComponent, componentProps: {vaId: vaId}
    });

    await modal.present();
    
  }

  async openProductImages(product: VendorArticle){

    const modal = await this.modalController.create({
      component: ProductImageModalComponent, componentProps: {product: product}
    });

    await modal.present();
    
  }

  async optionSelected(vendorArticle: VendorArticle){
    const modal = await this.modalController.create({
      component: ProductInfoModalComponent, componentProps: {vendorArticleId: vendorArticle.vendorArticleId}
    });

    await modal.present();
  }

  close(){

    this.modalController.dismiss();
    
  }




}
