import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-goods-in-po',
  templateUrl: './goods-in-po.component.html',
  styleUrls: ['./goods-in-po.component.scss']
})
export class GoodsInPoComponent  implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  close(){

      this.modalController.dismiss();
    
    
  }

}
