import { NgModule, ChangeDetectorRef, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ClickCollectOrderComponent } from './modals/click-collect-order/click-collect-order.component';
import { DatePipe } from '@angular/common';
import { GoodsInPoComponent } from './modals/goods-in-po/goods-in-po.component';
import { ConnectControlCentreService } from './services/connect-control-centre.service';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { GlobalService } from './services/global.service';
import { ProductInfoModalComponent } from './modals/product-info-modal/product-info-modal.component';
import { SanitizedHtmlPipe } from './pipes/sanitized-html.pipe';
import { ProductImageModalComponent } from './modals/product-image-modal/product-image-modal.component';
import { ProductInfoComponent } from './components/product-info/product-info.component';
import { TillOperatorModalComponent } from './modals/till-operator-modal/till-operator-modal.component';
import { PoGoodsInModalComponent } from './pages/goods-in/po-goods-in/po-goods-in-detail/po-goods-in-modal/po-goods-in-modal.component';
import { OptionListComponent } from './components/option-list/option-list.component';
import { OptionListComponent as OptionListModal } from './modals/option-list/option-list.component';

import { Device } from '@ionic-native/device/ngx';
import { SiteSelectComponent } from './modals/site-select/site-select.component';
import { FormsModule } from '@angular/forms';
import { CategoryModalComponent } from './pages/products/category-modal/category-modal.component';
import { WarningModalComponent } from './modals/warning-modal/warning-modal.component';



@NgModule({
    declarations: [AppComponent, ClickCollectOrderComponent, GoodsInPoComponent, ProductImageModalComponent, ProductInfoModalComponent, OptionListComponent, OptionListModal,
      ProductInfoComponent, SanitizedHtmlPipe, TillOperatorModalComponent, SiteSelectComponent, CategoryModalComponent, WarningModalComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule, ReactiveFormsModule],
    providers: [
        StatusBar,
        SplashScreen,
        DatePipe,
        Device,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initializeApp,
        //     multi: true,
        //     deps: [ConnectControlCentreService, GlobalService],
        // }

    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

// function initializeApp(connectService: ConnectControlCentreService) {
//     //return firstValueFrom(connectService.getSites());
//     return async () => firstValueFrom(connectService.getSites());
//   }

  export function initializeApp(connectService: ConnectControlCentreService, globalService: GlobalService): () => Observable<any> {
    return () => connectService.getSites().pipe(    // load() function returns Observable
       tap((sites) => {
          globalService.sites = sites;
       })
    )
  }