import { Injectable } from '@angular/core';
import { Site } from '../objects/site';
import { TillOperator } from '../objects/tillOperator';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { ConnectControlCentreService } from './connect-control-centre.service';
import { Observable, firstValueFrom } from 'rxjs';
import { TillOperatorModalComponent } from '../modals/till-operator-modal/till-operator-modal.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';

enum GoodsInReasonCodes {
  PurchaseOrder = 1,
  CustomerReturn,
  VendorReplace,
  Intersite,
  StockTake,
  Correction
};

enum DomainData {
  GOODS_IN = 13,
  GOODS_OUT = 14
}



@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  //ionic cordova run android --device
  //ionic cordova build android

  currentSite: Site;
  currentUser: TillOperator;
  loading;
  sites: Site[] = [];
  activeSite: Site = null;
  imageNotFound = "/assets/no-image-icon-23483.png";
  version = "0.24";
  testProduct = "BA0453"; //"OA0003"; //"E0649"; //"CA0005"; //"NC8990";
  activeSiteMode = "select"; // "radio"

  goodsInReasons = [
    {name: "Purchase Order", code: GlobalService.GoodsInReasonCodes.PurchaseOrder},
    {name: "Customer Return", code: GlobalService.GoodsInReasonCodes.CustomerReturn},
    {name: "Vendor Replacement", code: GlobalService.GoodsInReasonCodes.VendorReplace},
    {name: "Stock Transfer", code: GlobalService.GoodsInReasonCodes.Intersite},
    {name: "Stock Take (Incremental)", code: GlobalService.GoodsInReasonCodes.StockTake},
    {name: "Correction", code: GlobalService.GoodsInReasonCodes.Correction}
  ]

  
  

  constructor(private toastController: ToastController, private loadingController: LoadingController, 
    private modalController: ModalController, private alertController: AlertController) { 
    //TODO
    this.currentUser = new TillOperator;
    this.currentUser.nickname = "lee test";
    this.currentUser.tillOperatorId = 1111;
  }

  removeDotPrefix(text){
    if (text?.substring(0,4).indexOf(".") > -1){
      text = text.substring(text.indexOf(".") + 1).trim();
    }

    return text;
  }


  getSite(siteId){
    return this.sites.find(site => site.siteId == siteId);
  }

  async presentToast(position: 'top' | 'middle' | 'bottom', message, duration, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      color: color
    });

    await toast.present();
  }

  async presentLoading(message: string) {
    this.loading = await this.loadingController.create({
      message: message
    });

    this.loading.present();
  }

  dismissLoading(){
    
    if (this.loading)
      this.loading.dismiss();
  }

  async openWarningModal(message){
    const modal = await this.modalController.create({
      component: WarningModalComponent, componentProps: {message: message},
      
    });

    await modal.present();
  }



  async getSitesFromService(){

  }

  convertImgUrl(url){
    if (!url) return this.imageNotFound;
    return url.replace("http://images.intelligentretail.co.uk", "https://876bd88d793670224e3b-07908e399c88f12f1502eba2688806e4.ssl.cf3.rackcdn.com");
  }

  

  
  

  
}

export namespace GlobalService {
  export enum GoodsInReasonCodes {
      PurchaseOrder = 1120, CustomerReturn = 1121, VendorReplace = 1122, Intersite = 1124, StockTake = 1119, Correction = 1127
  }
}