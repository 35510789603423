import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'click-and-collect',
    loadChildren: () => import('./click-and-collect/click-and-collect.module').then( m => m.ClickAndCollectPageModule)
  },
  {
    path: 'stock-lookup',
    loadChildren: () => import('./pages/stock-lookup/stock-lookup.module').then( m => m.StockLookupPageModule)
  },
  {
    path: 'stock-take',
    loadChildren: () => import('./pages/stock-take/stock-take.module').then( m => m.StockTakePageModule)
  },
  {
    path: 'goods-in',
    loadChildren: () => import('./pages/goods-in/goods-in.module').then( m => m.GoodsInPageModule)
  },
  {
    path: 'goods-out',
    loadChildren: () => import('./pages/goods-out/goods-out.module').then( m => m.GoodsOutPageModule)
  },
  {
    path: 'add-to-purchase-order',
    loadChildren: () => import('./pages/add-to-purchase-order/add-to-purchase-order.module').then( m => m.AddToPurchaseOrderPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'manual-goods-out',
    loadChildren: () => import('./pages/goods-out/manual-goods-out/manual-goods-out.module').then( m => m.ManualGoodsOutPageModule)
  },  {
    path: 'edit-product',
    loadChildren: () => import('./pages/products/edit-product/edit-product.module').then( m => m.EditProductPageModule)
  }

  // ,
  // {
  //   path: 'till-operator-login',
  //   loadChildren: () => import('./modals/till-operator-modal/till-operator-modal.component').then( m => m.TillOperatorModalComponent)
  // }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
