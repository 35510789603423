// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isDebugMode: true,
  apiUrl:  "https://ccs.intelligentretail.com/cloud-service-1.1.1", //cloud-service-1.1.1-SNAPSHOT //cloud-service-1.1.0
  firebird: "INTELLIGENT",//"KARELIAHOUSE2", //"TIVYHALL2", //"KIDSSTUFF", //"GIVETHEDOGABONE", //"GILSANSPORTS", //"FOXHOLES", //"INTELLIGENT", //"HELPDESKUATTESTING" //"KIDSSTUFF" "HHSLJ"
  logo: "assets/ir_logo.png", //"assets/kidsstuff.png" //"assets/gilsans.png" //"assets/foxholes.svg" //"assets/ir_logo.png"//"assets/kidsstuff.png"//"assets/exeter-angling-logo-white.svg"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
