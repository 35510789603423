import { Component, OnInit } from '@angular/core';
import { ConnectControlCentreService } from 'src/app/services/connect-control-centre.service';
import { firstValueFrom } from 'rxjs';
import { SiteStock } from 'src/app/objects/siteStock';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-stock-matrix',
  templateUrl: './stock-matrix.component.html',
  styleUrls: ['./stock-matrix.component.scss'],
})
export class StockMatrixComponent  implements OnInit {

  vaId;
  siteStock: SiteStock[] = [];

  constructor(private connectService: ConnectControlCentreService, private modalController: ModalController) { }

  ngOnInit() {
    this.getMatrix(this.vaId);
  }

  async getMatrix(vaId){
    this.siteStock = await firstValueFrom(this.connectService.getStockMatrix(vaId));
  }

  close() {
    this.modalController.dismiss(null, 'cancel');
  }

  

}
