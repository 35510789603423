import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductInfoModalComponent } from 'src/app/modals/product-info-modal/product-info-modal.component';
import { VendorArticle } from 'src/app/objects/vendorArticle';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
})
export class OptionListComponent  implements OnInit {

  @Input() options: VendorArticle[] = [];
  @Output() optionSelectedEvent = new EventEmitter<VendorArticle>();
  isBusy = true;

  constructor(private modalController: ModalController, public globalService: GlobalService) { }

  ngOnInit() {}

  async openProductInfo(vendorArticle: VendorArticle){
    this.isBusy = true;
    const modal = await this.modalController.create({
      component: ProductInfoModalComponent, componentProps: {vendorArticleId: vendorArticle.vendorArticleId},id: 'product-info'
      
    });

    await modal.present();
    this.isBusy = false;
  }

  async optionOnClick(VendorArticle: VendorArticle){
    this.optionSelectedEvent.emit(VendorArticle);
  }



}
