import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VendorArticle } from 'src/app/objects/vendorArticle';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-product-image-modal',
  templateUrl: './product-image-modal.component.html',
  styleUrls: ['./product-image-modal.component.scss'],
})
export class ProductImageModalComponent  implements OnInit {

  product: VendorArticle = new VendorArticle;

  constructor(private modalController: ModalController, public globalService: GlobalService) { }

  ngOnInit() {}

  close(){

    this.modalController.dismiss();
    
  }

}
