import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { GlobalService } from './services/global.service';
import { AuthenticationService } from './services/authentication.service';
import { Site } from './objects/site';
import { ConnectControlCentreService } from './services/connect-control-centre.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Device } from '@ionic-native/device/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  sites: Site[] = [];
  environment = environment;
  logoClicks = 0;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthenticationService,
    public globalService: GlobalService,
    private device: Device,
  ) {
    this.initializeApp();
    
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.authService.tillOperatorLogin();
    });
  }

  ngOnInit() {
    
  }

  logout(){
    this.authService.tillOperatorLogin();
  }

  logoClicked(){
    this.logoClicks++;

    if (this.logoClicks > 5){
      this.globalService.presentToast("middle",this.device.uuid,200000, "warning");
    }

  }

  closeMenu(){
    
  }

  
}


