import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss'],
})
export class CategoryModalComponent  implements OnInit {

  categories;
  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  closeModal(){

    this.modalController.dismiss();
  
  }

  onCatSelected(cat){
    this.modalController.dismiss(cat);
  }

}
